import { CompanyBadgeCard, SidebarSection } from '@paddl/storybook';
import {
  bool,
  number,
  shape,
  string
} from 'prop-types';
import React, { useState } from 'react';
import { WorkspaceDirectoryModal } from './WorkspaceDirectoryModal';
import { ProfileTopListByXp } from '../../components/ProfileTopListByXp/ProfileTopListByXp';

export const WorkspaceSidebar = ({
  workspaceOverview, isLoading
}) => {
  const [isProfileDirectoryOpen, setIsProfileDirectoryOpen] = useState(false);

  const {
    workspaceId, logoUrl, workspaceName, totalMembers, totalXp
  } = workspaceOverview || {};
  if (isLoading) return null;

  return (
      <div>
      <SidebarSection>
        <CompanyBadgeCard
          logo={logoUrl || ''}
          name={workspaceName}
          numberOfProfiles={totalMembers}
          totalXp={totalXp}
          membersLinkOnClick={() => setIsProfileDirectoryOpen(true)}
        />
      </SidebarSection>
      <SidebarSection>
        <ProfileTopListByXp workspaceId={workspaceId} />
      </SidebarSection>
      {isProfileDirectoryOpen && <WorkspaceDirectoryModal
        isModalOpen={isProfileDirectoryOpen}
        workspaceId={workspaceId}
        onClose={() => setIsProfileDirectoryOpen(false)}
      />}
      </div>
  );
};

WorkspaceSidebar.defaultProps = {
  isLoading: false,
  workspaceOverview: null
};

WorkspaceSidebar.propTypes = {
  workspaceOverview: shape({
    workspaceId: number.isRequired,
    workspaceName: string.isRequired,
    logoUrl: string,
    totalMembers: number,
    totalXp: number
  }
  ),
  isLoading: bool

};
