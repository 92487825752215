import React from 'react';
import { number, string, oneOfType } from 'prop-types';
import { Leaderboard } from '@paddl/storybook';
import { hardRedirect } from '@paddl/utils-js';
import { useResource } from '@paddl/utils-react';

const PROFILES_IN_SUMMARY = 5;
const TOTAL_PROFILES = 10;
export const ProfileTopListByXp = ({ workspaceId }) => {
  const { data } = useResource(`${process.env.AT_WORK_API}/v2/workspace/${workspaceId}/leaderboard`);

  return (
    <Leaderboard
      data-test-id="team-leaderboard"
      onProfileClick={(profile) => hardRedirect('profileBySlugOrId', { id: profile.id, slug: profile.urlSlug })}
      profiles={data?.leaderboard}
      profilesInSummary={PROFILES_IN_SUMMARY}
      totalProfiles={TOTAL_PROFILES}
    />
  );
};

ProfileTopListByXp.propTypes = {
  workspaceId: oneOfType([
    string,
    number
  ]).isRequired
};
