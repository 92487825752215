import {
  ModularPage
} from '@paddl/storybook';
import {
  hardRedirect
} from '@paddl/utils-js';
import {
  Metadata,
  ProgramPageContent,
  MemberAppNavigation_Populated,
  useResource,
  ProgramDirectoryModal,
  ProgramPageSidebarContent,
  shouldDisplayProgramMembers
} from '@paddl/utils-react';
import {
  shape,
  string
} from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

export const Program = ({
  match: {
    params: { organisationId, programId }
  }
}) => {
  const [isProfileDirectoryOpen, setIsProfileDirectoryOpen] = useState(false);

  const programUrl = `${process.env.AT_WORK_API}/v2/program/${programId}`;

  const { data: programResponse, isLoading: isProgramLoading } =
    useResource(programUrl);

  const shouldFetchProfiles = shouldDisplayProgramMembers(programResponse?.program);

  const { data: profilesData } = useResource(`${process.env.AT_WORK_API}/v2/program/${programId}/profile-previews`, !shouldFetchProfiles);

  let program;
  if (shouldFetchProfiles && profilesData) {
    const { profilePreviews, totalProgramMembers } = profilesData;
    program = {
      ...programResponse.program,
      profilesInProgram: {
        profilePreviews,
        numberOfProfiles: totalProgramMembers
      }
    };
  } else {
    program = programResponse?.program;
  }

  useEffect(() => {
    if (window?.analytics && programResponse?.program) {
      window.analytics.page(
        'Workspace App',
        'Program',
        {
          programId,
          programTitle: programResponse.program.title,
          providerId: programResponse.program.providerId,
          workspaceId: organisationId
        });
    }
  }, [programResponse, programId, organisationId]);

  useEffect(() => {
    if (
      !isProgramLoading && !programResponse?.program
    ) {
      console.error('No program found, redirecting.');
      hardRedirect('explore');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [program, programResponse]);

  const openProfileDirectory = useCallback(() => {
    setIsProfileDirectoryOpen(true);
  }, []);

  const breadcrumbItems = [
    {
      title: 'Workspace',
      action: () => {
        hardRedirect('workspaceById', { id: organisationId });
      }
    },
    { title: 'Program' }
  ];

  const pageContent = program ? <ProgramPageContent
    program={program}
    openProfileDirectory={openProfileDirectory}
    breadcrumbItems={breadcrumbItems}
  /> : null;

  let sidebarContent;
  if (program) {
    sidebarContent = <ProgramPageSidebarContent program={program} openProfileDirectory={openProfileDirectory} />;
  } else {
    sidebarContent = null;
  }

  return (
   <div>
      <Metadata title={`Program ${program?.title || ''} | Paddl`} />
      <ModularPage
        pageName={'Program'}
        pageContent={pageContent}
        sidebarContent={sidebarContent}
        navigationContent={<MemberAppNavigation_Populated currentPage="workspace" />}
        breadcrumbItems={breadcrumbItems}
        pageBackground="dark"
      />
     {isProfileDirectoryOpen && <ProgramDirectoryModal
       isModalOpen={isProfileDirectoryOpen}
       programId={programId}
       onClose={() => setIsProfileDirectoryOpen(false)}
     />}
   </div>
  );
};

Program.propTypes = {
  match: shape({
    params: shape({
      organisationId: string.isRequired,
      programId: string.isRequired
    }).isRequired
  }).isRequired
};

Program.defaultProps = {};
