export const profilesIntroText = (profiles) => {
  const totalProfiles = profiles?.length || 0;
  if (totalProfiles === 0) {
    return '';
  }

  if (totalProfiles === 1) {
    return profiles[0].fullName;
  }

  if (totalProfiles === 2) {
    return `${profiles[0].fullName} and ${profiles[1].fullName}`;
  }

  if (totalProfiles === 3) {
    return `${profiles[0].fullName}, ${profiles[1].fullName} and one other.`;
  }

  return `${profiles[0].fullName}, ${profiles[1].fullName} and ${totalProfiles - 2} others.`;
};
