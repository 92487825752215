import {
  ModularPage
} from '@paddl/storybook';
import {
  hardRedirect
} from '@paddl/utils-js';
import {
  MemberAppNavigation_Populated,
  hasAlreadyFinished,
  sortByLiveEventDate,
  useResource
} from '@paddl/utils-react';
import {
  shape,
  string
} from 'prop-types';
import React, {
  useEffect,
  useMemo,
  useState
} from 'react';
import { EmptyState } from './EmptyState';
import { WorkspacePageContent } from './WorkspacePageContent';
import { WorkspaceSidebar } from './WorkspaceSidebar';

const getFirstProgramWithExperience = (programs, experienceId) => {
  return programs.find(({ experiences }) => experiences && experiences.includes(experienceId)).id;
};

export const Workspace = ({
  match: {
    params: { workspaceId }
  }
}) => {
  const liveExperienceEndpoint = `${process.env.AT_WORK_API}/v2/workspace/${workspaceId}/programs/experiences/live/summary`;

  const { data: liveExperienceData, isLoading: isLiveExperienceDataLoading } =
    useResource(liveExperienceEndpoint);

  const onDemandEndpoint = `${process.env.AT_WORK_API}/v2/workspace/${workspaceId}/programs/experiences/on-demand/summary`;

  const { data: onDemandExperienceData, isLoading: isOnDemandExperienceDataLoading } =
   useResource(`${onDemandEndpoint}?limit=12&progress=incomplete&sortBy=random`);

  const programEndpoint = `${process.env.AT_WORK_API}/v2/workspace/${workspaceId}/programs`;
  const { data: overviewData, isLoading: isOverviewLoading } =
   useResource(`${process.env.AT_WORK_API}/v2/workspace/${workspaceId}/overview`);

  const [isAccessLoading, setIsAccessLoading] = useState(true);

  const { data: programData, isLoading: isProgramsLoading } = useResource(programEndpoint);

  const { data: roleData, isLoading: isRoleLoading } = useResource(`${process.env.AT_WORK_API}/v2/workspace/${workspaceId}/my-role`);

  useEffect(() => {
    if (window?.analytics) {
      window.analytics.page(
        'Workspace App',
        'Workspace',
        {
          workspaceId
        });
    }
  }, [workspaceId]);

  useEffect(() => {
    if (isRoleLoading) return;
    if (!roleData?.role) hardRedirect('explore');
    if (roleData?.role === 'invited') hardRedirect('workspaceGatewayById', { id: workspaceId });
    setIsAccessLoading(false);
  }, [isRoleLoading, roleData, workspaceId]);

  const reccomendedOnDemandExperiences = useMemo(() => {
    if (!onDemandExperienceData?.experiences) return [];

    return onDemandExperienceData.experiences
      .map((experience) => (
        {
          ...experience,
          programId: getFirstProgramWithExperience(onDemandExperienceData.programs, experience.id)
        }
      ));
  }, [onDemandExperienceData]);

  const upNextExperiences = useMemo(() => {
    if (!liveExperienceData?.experiences) return [];

    return liveExperienceData.experiences
      .filter((experience) => !hasAlreadyFinished(experience))
      .sort(sortByLiveEventDate)
      .map((experience) => ({
        ...experience,
        programId: getFirstProgramWithExperience(liveExperienceData.programs, experience.id)
      }));
  }, [liveExperienceData]);

  const pageTitle = `${overviewData?.workspaceOverview?.workspaceName || ''} Workspace`;
  let pageContent;
  if (isAccessLoading) pageContent = null;
  else if (!isProgramsLoading && programData?.programs?.length === 0) pageContent = <EmptyState />;
  else {
    pageContent = <WorkspacePageContent
      organisationId={parseInt(workspaceId, 10)}
      pageTitle={pageTitle}
      programs={programData?.programs}
      recommendedExperiences={reccomendedOnDemandExperiences}
      upNextExperiences={upNextExperiences}
      isLiveExperienceDataLoading={isLiveExperienceDataLoading}
      isOnDemandExperienceDataLoading={isOnDemandExperienceDataLoading}
    />;
  }

  let sidebarContent;
  if (isAccessLoading) {
    sidebarContent = null;
  } else {
    sidebarContent = <WorkspaceSidebar workspaceOverview={overviewData?.workspaceOverview} isLoading={isOverviewLoading} />;
  }

  return (
    <ModularPage
      pageName={'Workspace'}
      pageContent={pageContent}
      sidebarContent={sidebarContent}
      navigationContent={<MemberAppNavigation_Populated currentPage="workspace" />}
      breadcrumbItems={[{ title: pageTitle }]}
    />);
};

Workspace.propTypes = {
  match: shape({
    params: shape({
      workspaceId: string.isRequired
    }).isRequired
  }).isRequired
};
