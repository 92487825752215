import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { initialiseBrowserErrorTracking } from '@paddl/utils-js';
import { BaselineStylesProvider } from '@paddl/storybook';
import { Auth0ProviderWithHistory, LoginGate, FeatureToggleProvider } from '@paddl/utils-react';
import { GlobalTypography } from '@paddl/storybook/lib/Foundation/Fonts/GlobalTypography';
import { Router as RouteComponent } from './router/Router';

initialiseBrowserErrorTracking({
  applicationName: process.env.PACKAGE_NAME,
  sentryDsn: process.env.SENTRY_DSN,
  nodeEnv: process.env.NODE_ENV
});

ReactDOM.render(
  <BaselineStylesProvider>
    <GlobalTypography />
    <FeatureToggleProvider>
    <Router>
      <Auth0ProviderWithHistory>
      <LoginGate>
          <Route
            component={RouteComponent}
          />
      </LoginGate>
      </Auth0ProviderWithHistory>
    </Router>
    </FeatureToggleProvider>
  </BaselineStylesProvider>,
  document.getElementById('root')
);
