import {
  Artworks,
  Button,
  FeedFooter,
  InnerContentWrapper,
  MainContentWrapper,
  PageColumn,
  PageHeader
} from '@paddl/storybook';
import {
  hardRedirect
} from '@paddl/utils-js';
import React from 'react';

export const EmptyState = () => {
  return (
      <MainContentWrapper colorVariant="dark">
        <InnerContentWrapper size="small">
      <PageColumn style={{
        alignItems: 'center', textAlign: 'center'
      }}
      >
        <Artworks.womanOnSession />
        <PageHeader heading="This Workspace appears to be empty." tagline="Contact your Administrator to request new upskilling programs." />

        <Button onClick={() => hardRedirect('explore')} variant="contained" color="purple">Go to Explore</Button>

      <FeedFooter sx={{ alignSelf: 'normal' }} />
      </PageColumn>
        </InnerContentWrapper>
      </MainContentWrapper>
  );
};
