import { Workspace, Gateway, Program } from '../pages';
import { Experience } from '../pages/Experience/Experience';
import { NotFound } from '../pages/NotFound';

export const routerConfig = [
  {
    path: '/workspace/:organisationId/gateway',
    component: Gateway
  },
  {
    path: '/workspace/:organisationId/program/:programId/experience/:experienceId',
    component: Experience
  },
  {
    path: '/workspace/:organisationId/program/:programId',
    component: Program
  },
  {
    path: '/workspace/:workspaceId',
    component: Workspace
  },
  {
    path: '/workspace/',
    component: NotFound
  }
];
